import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import '../Plantation/Slider.scss';
import './BannerDemo.scss';
import AllButton from '../Plantation/AllButton';
import { Link } from 'react-router-dom';

function BannerDemo() {

    const images = [
        { url: "/NewBanner1.jpg" },
        { url: "/NewBanner2.jpg" },
        { url: "/NewBanner3.jpg" },  
      ];

  return (
    <div>
        
        <div className='Plantation-content'>
            <h2>Growth<br/>Transformation<br/> Innovation.</h2>
            <p style={{paddingBottom:'25px'}}>We turn your business into a growth powerhouse through transformation <br/>and innovation.</p>
            {/* <AllButton/> */}
            <Link to="/contact">
            <AllButton cta="Schedule Free Consultation" />         
                                  </Link>
             
            {/* <a href="https://www.grow-trees.com/projectdetails.php?id=152" target="blank"><button>Let’s grow a sustainable future</button></a> */}
           
        </div>
          
        
 <SimpleImageSlider
        width="100%"
        height= "91vh"
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        autoPlayDelay={3.9}
        responsive={true}
      />
    </div>
  )
}

export default BannerDemo