import React from 'react';
import Form from 'react-bootstrap/Form';
import './ContactForm.scss';
import {useRef, useState} from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function ContactForm() {

  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbybWTW3ck1l2DrlL-EBcDXmIWLyEh74HbhB8Vzbrz6GI8x1IV1dHEzZqfWsdt_zH0BKWg/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
    console.log("clicked")
      e.preventDefault()
      setLoading(true)
      console.log(formRef.current);

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),
      

  }).then(res => {
          alert("SUCCESSFULLY SUBMITTED")
          setLoading(false)
      })
      .catch(err => console.log(err))
  }
  return ( 
    <div className='contactform-wrapper'>
       <h2>Let’s Talk About Your <b>Business Goals.</b></h2> 
       <Form className='contact-form' ref={formRef} onSubmit={handleSubmit} name="google-sheet" >
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId="contactForm.ControlInput1" >
                  <Form.Control type="text" name="FirstName" placeholder="First Name" />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                  <Form.Control type="text" name="LastName" placeholder="Last Name" />
                </Form.Group>
            </Col> 
        </Row>
        
        <Row> 
          <Col>
        <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
          <Form.Control type="email" name="Email" placeholder="E-mail Address" />
        </Form.Group>
          </Col>
          <Col>
        <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
          <Form.Control type="text" name="Number" placeholder="Contact Number" />
        </Form.Group>
        </Col>
        </Row>
        {/* <Form.Group className="mb-3 position-relative" controlId="contactForm.ControlInput1">
          <Form.Select aria-label="Default select example" name="Service">
            <option disabled selected>How can we help you with?</option>
            <option>Business Transformation</option>
            <option>Supply Chain Management</option>
            <option>CFO &amp; Board Advisory</option>
            <option>Digital Strategy</option>
            <option>Go-To-Market</option>
            <option>Resolution Advisory</option>
            <option>Debt Capital</option>
            <option>Valuation Services</option>
            <option>Due Diligence</option>
            <option>Merger &amp; Acquisitions</option>
          </Form.Select>
          <KeyboardArrowDownIcon className='select-arrow' />
        </Form.Group> */}
        <Form.Group className="mb-3" controlId="contactForm.ControlTextarea1">
          <Form.Control as="textarea" rows={3} placeholder='Message' name="Message" />
        </Form.Group>

        <Form.Check 
            className='pt-2 pb-4'
            type={'checkbox'}
            id={'check'}
            label={'By clicking Send, I agree to the terms & privacy policy.'}
            style={{color: '#151515'}}
          />

        <Button variant="outline-secondary" type="submit" className="submit-btn btn-block" ><strong>Schedule Free Consultation</strong></Button>

        
      </Form>
    </div>
  )
}

export default ContactForm
