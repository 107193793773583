import React from 'react';
import './PageTop.scss';
import { Col, Container, Row } from 'react-bootstrap';

function PageTop({headingFirstHalf, headingSecondHalf, paragraph, belowHeading}) {
  return (
    <div className='pagetop'>
        <Container className='pagetop-container'>
            <Row>
                <Col xs={12} sm={12} md={12} lg={6}>
                {/* <h6>{pageName}</h6> */}
                <h1>{headingFirstHalf} <br/> {headingSecondHalf} </h1>
                <p>{belowHeading}</p>
                {/* <p>Gear up for the next!</p> */} 
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className='pagetop-col-right'>
                    <div className='pagetop-col-right-content'>
                    <p>{paragraph} </p>
                    </div>
                
                </Col>
            </Row>
        </Container>

    </div>
  )
}

export default PageTop