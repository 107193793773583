import React from 'react';
import './ServiceSection.scss';

function ServiceSection(props) {
  return (
    <div className='SS-Container'>
      <div className='SS-box1'>
        <img src='/service-icons/Vector.png' alt='Service Icons' />
        <h2>{props.S1}</h2>
        <p>{props.D1}</p>
      </div> 
 
      <div className='SS-box1'>
        <img src='/service-icons/Vector.png' alt='Service Icons' />
        <h2>{props.S2}</h2>
        <p>{props.D2}</p>
      </div>

      <div className='SS-box1'>
        <img src='/service-icons/Vector.png' alt='Service Icons' />
        <h2>{props.S3}</h2>
        <p>{props.D3}</p>
      </div>

      <div className='SS-box1'>
        <img src='/service-icons/Vector.png' alt='Service Icons' />
        <h2>{props.S4}</h2>
        <p>{props.D4}</p>
      </div>
        
    </div>
  )
}

export default ServiceSection