import React from 'react';
import './Expert.scss';

function Expert() {
  return (
    <div className='Expert-Container'>
        <div className='Expert-heading'>
            <div className='Expert-heading-wrapper'>
                <h2>Meet Our Expert</h2>
                {/* <img src='/heading-icon.png' alt="Arrow heading icon"/> */}
            </div>
            
        </div>
        <div className='Expert-content'>
            <div className='Expert-content-left'>
                <img src='/expert.jpg' alt="Expert Image" style={{objectFit:'contain'}}/>
                 
            </div>
            <div className='Expert-content-right'>
                <img src='/Quote-icon.png' alt="Quote Icon" style={{width:'70px'}}/>
                <p>A fellow Member of the Institute of <span style={{color:'#566EC8', fontWeight:'600'}}>Chartered Accountants of India</span>, is a Registered Insolvency Professional and ADR specialist with over <span style={{color:'#566EC8', fontWeight:'600'}}>30 years of experience</span> in corporate finance. He has led successful business transformations, M&A transactions, and strategic partnerships in logistics and telecom, delivering significant results and driving performance improvement.</p>
                {/* <p>We help you seize opportunities for profitable growth, redesign organizational structures, and unlock the <span style={{color:'#566EC8', fontWeight:'600'}}>power of information.</span> Finance teams need external factors—influence the bottom line of the business. redesign <span style={{color:'#566EC8', fontWeight:'600'}}>organizational structures,</span> and unlock the power of information</p> */}
                <h4>Sanjeev Jain</h4>
                <h5>Partner</h5>
            </div>
            
        </div>
    </div>
  )
}

export default Expert