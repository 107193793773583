import React from 'react';
import './BannerSection.css';
// import img1 from "./images/pharma.jpg";
// import img2 from '../../public/BannerSection2.jpg';
// import img3 from '.asset/BannerSection3.jpg';

function BannerSection() {

    // const data = [img1, img2, img3];

  return (
    <div className='BS-Slider'>
        <div className='BS-Slider-img'>
            {/* <img src={data[0]} alt=" " style={{width:'100%', height:'80vh'}} /> */}

            <div className='BS-Slider-btn'>
              <button>-</button>
              <button>+</button>
            </div>
        </div>
    </div>
  )
}

export default BannerSection