import React from 'react'
import './AboutTeam.css';
import TeamHome from '../home/TeamHome/TeamHome'
import TeamCard from '../home/TeamHome/TeamCard';

function AboutTeam({heading}) {
  return (
    <div className='AboutTeam'>
        
        <h2 style={{textAlign:'center', fontSize:'2.5rem', fontWeight:'600', color:'#151515'}}>OUR TEAM</h2> 
  
     <TeamHome/>
    


     {/* <section className='AboutTeam-section'>
     <TeamCard
     name='PULKIT VIJAY'
     work='CONSULTANT'
     image='./pulkit.png' 
     linkedin ='https://in.linkedin.com/in/pulkit-vijay'
     />
     <TeamCard className='aboutTeam-card'
     name='VIRAJ THAKUR'
     work='CONSULTANT'
     image='./viraj.png' 
     linkedin ='https://www.linkedin.com/in/thakurviraj'
     />
     <TeamCard className='aboutTeam-card'
     name='NIRAD INAMDAR'
     work='Sr. Manager - BD'
     image='./nirad.png' 
     linkedin ='https://www.linkedin.com/in/nirad-i-89a7b37'
     />
     </section>  */}
     
    
   
    
     
    
    </div>
  )
}

export default AboutTeam