import React from 'react';
import './Content.scss';

function Content(props) {
  return (
    <div className='mainContent-Container'>
        <div className='MC-framework'>
          <img src={props.FrameworkImg} alt='Framework images' />
        </div> 
        <div className='MC-wrapper'>
            <div>   
            {/* <button>Business Overview</button> */}
            <h2>{props.MainDesc}</h2>
            <p>{props.Desc}</p>
            <button>{props.Approach}</button>
            <p>{props.ApproachDesc}</p>
              <ul>
                <li><strong>{props.L1Bold}</strong>{props.L1}</li>
                <li><strong>{props.L2Bold}</strong>{props.L2}</li>
                <li><strong>{props.L3Bold}</strong>{props.L3}</li>
                <li><strong>{props.L4Bold}</strong>{props.L4}</li>
                <li><strong>{props.L5Bold}</strong>{props.L5}</li> 
              </ul>

            </div>
        </div>
 
    </div>
  )
}

export default Content