import React from 'react';
// import './ServiceLeft.scss';
import {Helmet} from "react-helmet";
import Top from './Top';
import ContentArea from './ContentArea';
import ServiceOffering from './ServiceOffering';
// import TiltBox from './TiltBox';
import FAQ from './FAQ';
import CaseStudy from '../ServicePages/CaseStudy';
import LandingForm from '../ServicePages/LandingForm';
import Content from '../ServicePages/Content';
import YTVideo from '../ServicePages/YTVideo';
import Expert from '../ServicePages/Expert';
import ServiceSection from '../ServicePages/ServiceSection';

// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';


function BusinessTransformation() {

  const faqDesign = {
    width: '85%',
    paddingBottom: '45px',
  }

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What are business transformation services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Business transformation services encompass a range of consulting offerings aimed at helping organizations navigate complex change initiatives. These services may include strategic planning, process reengineering, technology implementation, organizational restructuring, change management, and performance optimization."
      }
    },{
      "@type": "Question",
      "name": "What is the role of business transformation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Business transformation enables organizations to adapt, evolve, and thrive in response to internal and external challenges and opportunities. Business transformation drives sustainable growth, resilience, and competitive advantage by redefining strategies, optimizing operations, fostering innovation, and nurturing a culture of continuous improvement."
      }
    },{
      "@type": "Question",
      "name": "What does business transformation mean at Amicus Growth Advisors?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At Amicus Growth Advisors, business transformation entails a comprehensive process of reimagining and restructuring businesses to adapt to changing market dynamics, technological advancements, and evolving customer preferences. We focus on optimizing operations, enhancing efficiency, fostering innovation, and driving sustainable growth for our clients."
      }
    },{
      "@type": "Question",
      "name": "What is the mission of the Business Transformation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The mission of the Business Transformation is to empower organizations to thrive in dynamic environments by facilitating strategic change initiatives that unlock growth, innovation, and operational excellence. We are dedicated to guiding our clients through transformative journeys that drive long-term value and sustainable success."
      }
    },{
      "@type": "Question",
      "name": "What makes us unique in Business Transformation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We stand out for our deep industry expertise, innovative problem-solving approach, and commitment to delivering tangible results. We tailor our solutions to each client's unique needs, leveraging a blend of strategic insight, technology integration, and organizational change management to drive successful transformations."
      }
    },{
      "@type": "Question",
      "name": "How do Amicus Growth Advisors Work With project governance?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Amicus Growth Advisors collaborates closely with clients throughout every phase of the transformation journey. We begin by thoroughly understanding their business objectives, challenges, and opportunities. Our consultants then develop customized strategies and implementation plans, working with client teams to execute initiatives, monitor progress, and ensure sustainable outcomes."
      }
    },{
      "@type": "Question",
      "name": "Can you help a traditional business go digital?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely. Amicus Growth Advisors specializes in helping traditional businesses embrace digital transformation to stay competitive in today's rapidly evolving marketplace. We offer expertise in digital strategy development, technology adoption, process optimization, and organizational change management to facilitate a seamless transition to digital operations."
      }
    },{
      "@type": "Question",
      "name": "What are transformation strategies?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Transformation strategies are holistic approaches designed to drive significant changes within an organization. They encompass people, processes, technology, and culture and aim to align business objectives with market realities, capitalize on emerging opportunities, mitigate risks, and enhance overall performance and competitiveness."
      }
    }]
  

  };

  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Expert Business Consultants & Advisory Services | Amicus Growth Advisors</title>
            <meta name="keyword" content="business consultants, business advisory services, business transformation, business transformation consultant, business transformation strategy, business transformation agency, business transformation consulting" />
            <meta name="description" content="Our Business Consultants helps reimagine business transformation strategies to build a strategy that can create sustainable and inclusive growth. Discover The Power Of Data-Driven Consulting." />
            <link rel="canonical" href="https://www.amicusllp.com/services/business-consultants" />
            <script type="application/ld+json">
                {JSON.stringify(ldJson)}
            </script>
 
        </Helmet>


        <Top 
        image = '/BT-Top.jpg' 
        text="Consulting Services"
        heading1="Business"
        heading2="Transformation"
        desc="Reimagine your organization’s future with data-driven insights and bold innovation strategies."
        YTembed="BPWu0-E31u0"
        /> 
        
        <Content
        FrameworkImg="/BT-framework.png"
        MainDesc="For over 30 years, transformation has been at the core of who we are. Our programs are designed to unlock the full potential of your business, driven by a clear ambition to deliver fast results, strengthen digital capabilities, and drive lasting impact."
        Desc="Our business transformation experts work closely with you to identify growth opportunities and design tailored strategies aligned with your unique goals and organizational context. Partnering with us means redefining possibilities—we collaborate with you to achieve your ambitions, reshape your organization, and deliver results beyond expectations."
        Approach="What We Offer"
        ApproachDesc="We guide organizations through a strategic, results-driven process meticulously tailored to achieve measurable, sustainable, and transformative growth."
        L1Bold="Envision: "
        L1="Define the need for change and craft a vision aligned with long-term objectives."
        L2Bold="Engage: "
        L2="Empower leadership to mobilize resources and align with the transformation agenda."
        L3Bold="Transform: "
        L3="Redesign processes, operations, and culture to drive innovation."
        L4Bold="Optimize: "
        L4="Embed and refine initiatives to ensure efficiency and adaptability."
        L5Bold="Sustain:"
        L5="Handover strategies to in-house teams for long-term impact."
        />
         <ServiceSection
        S1="Business Performance Improvement"
        D1="Drive success through continuous process refinement."
        S2="Financial and Strategic Planning" 
        D2="Financial discipline drives long-term business resilience."
        S3="Project Management Implementation"
        D3="Proactive management avoids last-minute firefighting."
        S4="Operational Re-Engineering"
        D4="Re-engineering operations for the future-ready solutions."
        />        
        <YTVideo
        YTLink="https://www.youtube.com/watch?v=BPWu0-E31u0"
        YTImage='/BT-Top.jpg'
        />
        <Expert/>
 



        {/* <ContentArea  
        h1="Business Transformation"
        p1="We create leadership networks that empower individuals with the tools, perspectives, and strategies to address the complex challenges and drive sustainable success. Throughout your career, the greatest impact often comes from the opportunities to share insights, foster meaningful relationships, and collaborate with peers to tackle critical issues together."
        img="../bT.jpg"
        altTag="business-transformation-service"
        h2=""
        p2="Rather than concentrating solely on cost reduction, we partner with clients to implement comprehensive transformations. Our approach begins with reassessing strategic opportunities, focuses on driving revenue growth, and ensures a balanced, optimized financial foundation for lasting success."
        h3=""
        p3="We collaborate with clients to implement a proven methodology designed to maximize value at every stage. Built on extensive expertise, our approach ensures that successful transformations address both the strategy behind change and the execution needed to deliver lasting impact."
        p32=""
        h4=""
        p4="When swift and decisive action is critical, we bring the expertise to help companies stabilize finances, improve cash flow, streamline operations, and navigate complex challenges—all while aligning with smart, strategic priorities."
        p42="."
        /> */}

        {/* <TiltBox /> */} 
        {/* <ServiceOffering 
        heading="Our Business Transformation Services"
        s1="Business Performance Improvement"
        s2="Financial and Strategic Planning" 
        s3="Project Management Implementationg"
        s4="Operational Re-Engineering"
        />  */}

{/* <CaseStudy/> */}

        
        {/* <TeamBanner  
        image = './About-banner.png'
        quote = 'The Greater danger for most of us lies not in setting our aim too high and falling short; but in setting our aim too low, and achieving our mark.'
        /> */}
        
        <div style={{display:'flex', justifyContent:'center'}}>
          <div style={faqDesign}>
          <h2 style={{padding:'35px 0px'}}>Frequently Asked Questions</h2>
        <FAQ 
          th1="What are business transformation services?"
          FaqLink="https://www.amicusllp.com/services/sme-ipo-consultants"
          ds1="Business transformation services "
          ds12="encompass a range of consulting offerings aimed at helping organizations navigate complex change initiatives. These services may include strategic planning, process reengineering, technology implementation, organizational restructuring, change management, and performance optimization."
          th2="What is the role of business transformation?"
          ds2="Business transformation enables organizations to adapt, evolve, and thrive in response to internal and external challenges and opportunities. Business transformation drives sustainable growth, resilience, and competitive advantage by redefining strategies, optimizing operations, fostering innovation, and nurturing a culture of continuous improvement."
          th3="What does business transformation mean at Amicus Growth Advisors?"
          ds3="At Amicus Growth Advisors, business transformation entails a comprehensive process of reimagining and restructuring businesses to adapt to changing market dynamics, technological advancements, and evolving customer preferences. We focus on optimizing operations, enhancing efficiency, fostering innovation, and driving sustainable growth for our clients."
          th4="What is the mission of the Business Transformation?"
          ds4="The mission of the Business Transformation is to empower organizations to thrive in dynamic environments by facilitating strategic change initiatives that unlock growth, innovation, and operational excellence. We are dedicated to guiding our clients through transformative journeys that drive long-term value and sustainable success."
          th5="What makes us unique in Business Transformation?"
          ds5="We stand out for our deep industry expertise, innovative problem-solving approach, and commitment to delivering tangible results. We tailor our solutions to each client's unique needs, leveraging a blend of strategic insight, technology integration, and organizational change management to drive successful transformations."
          th6="How do Amicus Growth Advisors Work With project governance"
          ds6="Amicus Growth Advisors collaborates closely with clients throughout every phase of the transformation journey. We begin by thoroughly understanding their business objectives, challenges, and opportunities. Our consultants then develop customized strategies and implementation plans, working with client teams to execute initiatives, monitor progress, and ensure sustainable outcomes."
          th7="Can you help a traditional business go digital?"
          ds7="Absolutely. Amicus Growth Advisors specializes in helping traditional businesses embrace digital transformation to stay competitive in today's rapidly evolving marketplace. We offer expertise in digital strategy development, technology adoption, process optimization, and organizational change management to facilitate a seamless transition to digital operations."
          th8="What are transformation strategies?"
          ds81="Transformation strategies are holistic approaches designed to drive significant changes within an organization. They encompass people, processes, technology, and culture and aim to align business objectives with market realities, capitalize on emerging opportunities, mitigate risks, and enhance overall performance and competitiveness."
        />
        </div>
        </div>
        {/* <LandingForm/> */}
    </div>
  )
}

export default BusinessTransformation