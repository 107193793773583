import React from 'react';
import './ServiceLeft.scss';
import {Helmet} from "react-helmet";
import Top from './Top';
// import CaseStudy from '../ServicePages/CaseStudy';
import FAQ from './FAQ';
import Content from '../ServicePages/Content';
import ServiceSection from '../ServicePages/ServiceSection';
import YTVideo from '../ServicePages/YTVideo';
import Expert from '../ServicePages/Expert';

function CFOService() {

  const faqDesign = {
    width: '85%',
    paddingBottom: '45px',
  }

  return (
    <div>
      <Helmet>
            <meta charSet="utf-8" />
            <title>CFO Services | CFO Consultant - Amicus Growth Advisors</title>
            <meta name="keyword" content="cfo consultants, cfo services, chief financial officer" />
            <meta name="description" content="Amicus Growth Advisors offers unparalleled services in business transformation, SME IPOs, startup consulting, diagnostics, M&A, ESG advisory, and virtual CFO. Your strategic growth partner." />
            <link rel="canonical" href="https://www.amicusllp.com/services" />
        </Helmet>

        <Top 
        image = '/CFO-Banner.jpg' 
        text="Consulting Services"
        heading1="CFO"
        heading2="Services"
        desc="Ensuring businesses scale by creating robust financial structures and adaptive growth strategies."
        YTembed="BPWu0-E31u0"
        /> 

        <Content
        FrameworkImg="/CFO-Framework.png"
        MainDesc="Finance is the backbone of any thriving business. But to truly succeed, it takes more than just managing numbers—it takes visionary leadership. Our CFO services are designed to not only streamline your financial operations but also to unlock new growth opportunities and drive your business forward with confidence."
        Desc="We don’t just handle financials; we redefine them. From optimizing cash flow and ensuring compliance to leveraging data for strategic insights, we provide a roadmap for resilience and growth. By partnering with us, you gain more than a CFO—you gain a strategic ally committed to your long-term success. Let us turn your financial challenges into the building blocks of your future."
        Approach="What We Offer"
        ApproachDesc="Our CFO services are designed to address every critical financial aspect of your business, ensuring operational excellence and strategic growth. Here's what we bring to the table:"
        L1Bold="Financial Diagnosis: "
        L1="Assess your financial health, analyze statements, and identify inefficiencies to uncover areas for improvement."
        L2Bold="Integrated Strategy and Governance Framework"
        L2="Formulate budgets, forecasts, and KPIs aligned with business goals while reinforcing governance structures."
        L3Bold="Working Capital Management: "
        L3="Optimize cash flow and enhance liquidity through smarter financial management practices."
        L4Bold="Technology Integration: "
        L4="Leverage ERP systems and digital tools to automate processes and enhance efficiency."
        L5Bold="Performance and Financial Monitoring: "
        L5="Track KPIs, conduct variance analysis, and share actionable insights while streamlining financial close processes"

        />

        <ServiceSection
        S1="Working Capital Management"
        D1="Effective capital management fosters agility and financial strength."
        S2="Financial Planning & Analysis" 
        D2="Bridging financial metrics to impactful business performance outcomes."
        S3="Budget & Forecasting"
        D3="Aligning financial strategy to fuel business transformation efforts."
        S4="Capital Restructuring"
        D4="Transforming financial frameworks to drive sustainable business growth."
        />      
        <YTVideo
        YTLink="https://www.youtube.com/watch?v=iCfPw0h_5fo"
        YTImage='/CFO-Banner.jpg'
        /> 
        <Expert/>





{/* <ContentArea  
        h1="CFO Services"
        p1="Our team provides outstanding financial expertise and a comprehensive range of CFO services tailored to oversee financial operations, optimize bookkeeping processes, and offer strategic insights that foster long-term growth."
        img="../cfo.jpg"
        altTag="business-transformation-service"
        h2=""
        p2="Keeping your business ahead of the competition and achieving the success it deserves can be challenging. However, with effective financial planning, reaching your goals becomes more attainable."
        h3=""
        p3="A CFO expert provides strategic guidance, ensuring your business aligns financial decisions with long-term goals. They go beyond managing numbers, helping drive profitability, scalability, and sustainability."
        p32="From securing capital to managing investor relations, a CFO expert ensures your business is well-positioned to attract funding and expand successfully."
        h4=""
        p4="By leveraging CFO (Chief Financial Officer) services tailored for startups, you gain access to essential financial expertise and guidance. These services can help safeguard your business from potential financial risks while positioning it for sustainable growth"
        p42="Outsourced CFO services offer valuable financial support to business owners and your Companies. For small businesses and startups, hiring a full-time CFO can be both challenging and cost-prohibitive. Outsourced CFO services are an ideal solution, providing a clear understanding of your current financial position. With this insight, you can align your business strategies effectively, paving the way for accelerated growth and success."
        /> */}

    {/* <ServiceOffering 
        heading="Our Financial Planning Services"
        s1="Working Capital Management"
        s2="Financial Planning & Analysis" 
        s3="Budget & Forecasting"
        s4="Capital Restructuring"
        />   */}

    {/* <CaseStudy/> */}
   
         
            <div style={{display:'flex', justifyContent:'center'}}>
                <div style={faqDesign}>
                   <h2 style={{padding:'35px 0px'}}>Frequently Asked Questions</h2>
                  <FAQ 
                    th1="What are CFO services, and why are they important?"
                    FaqLink="https://www.amicusllp.com/services/sme-ipo-consultants"
                    ds1="CFO services "
                    ds12="provide financial leadership and strategic guidance to businesses, helping them manage budgets, improve financial performance, and align operations with long-term goals. These services are vital for ensuring fiscal health and driving sustainable growth."
                    th2="What types of businesses can benefit from CFO services?"
                    ds2="CFO services are ideal for businesses of all sizes, including start-ups, small and medium enterprises (SMEs), and even large corporations. Whether your business is scaling, facing complex financial challenges, or planning for growth, a CFO can add significant value."
                    th3="How can a CFO help improve cash flow?"
                    ds3="A CFO analyzes cash flow patterns, identifies inefficiencies, and develops strategies to optimize cash management, such as reducing unnecessary expenses, renegotiating vendor terms, and enhancing revenue collection processes."
                    th4="What role does a CFO play in business strategy?"
                    ds4="A CFO contributes to shaping the company’s strategic direction by providing financial insights, evaluating investment opportunities, and ensuring that financial goals align with overall business objectives."
                    th5="Can a CFO help with fundraising and investment?"
                    ds5="Yes, a CFO plays a crucial role in raising capital by preparing financial forecasts, engaging with investors, and presenting compelling business cases for funding. They also assess investment opportunities to maximize returns."
                    th6="How do CFO services support small and medium-sized businesses (SMBs)?"
                    ds6="CFO services for SMBs provide expert financial guidance at a fraction of the cost of a full-time CFO. These services help SMBs with budgeting, forecasting, financial reporting, and scaling operations effectively."
                    th7="Can a CFO help prepare for mergers or acquisitions?"
                    ds7="Yes, a CFO leads due diligence, evaluates financial risks and opportunities, and ensures smooth integration during mergers or acquisitions, helping businesses maximize value."
                    th8="How does a CFO handle financial risks?"
                    ds81="A CFO identifies potential risks, such as market volatility or regulatory changes, and develops mitigation strategies, including diversification, hedging, and compliance frameworks."
                  />
                 </div>
            </div>
           
    </div>
  )
}

export default CFOService