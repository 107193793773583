import React from 'react';
import './Top.scss';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../home/YoutubeEmbed';
import { Height } from '@mui/icons-material';
import LandingForm from '../ServicePages/LandingForm';
import ContactForm from '../ContactPage/ContactForm';
// import Background from '../career0.jpg';

function Top(props) { 

  const myStyle = {
    backgroundImage: "url(/BT-Top.jpg)",
    // background: "linear-gradient(#e66465, #9198e5)",
    // marginTop: "-70px",
    // fontSize: "50px", 
    // height: "660px", 
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
};

  return (
    // <div className='top' style={{ backgroundImage: `url(${props.image})` }}>
  
 
    // <div className='top' style={myStyle}>
       <div className='top' style={{ backgroundImage: `url(${props.image})`}}>
       <div className='top-left'>
            <div className='top-left-content'>
                <h3>{props.text}</h3>
                <h1>{props.heading1} <span><br/>{props.heading2}</span></h1>
                <p>{props.desc}</p> 

                <div className='top-button-wrapper'>
                  <div className='top-button-left'>
                      <Link to="/contact">  
                      <a style={{color:'white', fontSize:'1rem'}}>Contact Us</a>
                      </Link>
                  </div>
 
                  <div className='top-button-right'>
                      <a href="https://drive.google.com/file/d/1a18RfunC61u54Cp3ubT5FLI71F1NwlZl/view" target="_blank" style={{color:'#ffffff',fontSize:'.9rem'}}>Download Deck</a>    
                  </div>
               
                </div>

                <div className='top-left-review'>
                  <div className='top-left-review-1'>
                    <p>Industry Expertise</p>
                    <h2>12+</h2>
                  </div>
                  <div className='top-left-review-1'>
                  <p>Years of Experience</p>
                  <h2>30+</h2>
                  </div>
                </div>

            </div>
            </div>
            <div className='top-right-yt'>
                  <div className='top-right-yt-box'>
                    {/* <YoutubeEmbed embedId={props.YTembed}/> */}
                    <ContactForm/>
                  </div>
            </div>
        
       
    </div> 
    
  )
}

export default Top