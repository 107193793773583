import React from 'react';
import './YTVideo.scss';
// import YoutubeEmbed from '../home/YoutubeEmbed';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { WidthFull } from '@mui/icons-material';

function YTVideo(props) {

  const YTStyle = {
    backgroundImage: "url(/BT-Top.jpg)",
    // background: "linear-gradient(#e66465, #9198e5)",
    // marginTop: "-70px",
    // fontSize: "50px", 
    // Width:"900px", 
    // height: "560px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
};

  return (
    <div className='YT-Container'>
      <div className='YT-Wrapper' style={{ backgroundImage: `url(${props.YTImage})`}}>  
        {/* <a href='https://www.youtube.com/watch?v=BPWu0-E31u0'>  */}
        <a href={props.YTLink}> 
        <img src='/YT-icon.png' alt='Youtube Button Icon' className='YT-Wrapper-icon'/>
        </a> 
        {/* <img src='/NewBanner1.jpg' alt='Youtube Video' className='YT-Wrapper-img'/> */}
      </div>
     

        {/* <div className='YT-wrapper'>
            <div style={{display:'flex', justifyContent:'center', borderRadius:'1rem'}}>
            <LiteYouTubeEmbed 
              id="iCfPw0h_5fo" 
              title="What is Business Transformation"
            />
            </div>
        </div> */}
    </div>
  )
}

export default YTVideo